<template>
	<div>
		<b-card>
			<h1>Etapas</h1>
			<div class="mb-4">
				<h4 class="mb-0">Los cambios se reflejaran para las nuevas etapas de los siguientes lotes a crear</h4>
				<small>Los dias de una etapa no debe ser mayor a los dias posteriores de la siguiente</small>
			</div>
			<b-row>
				<b-col cols="12" lg="4" v-for="(item, index) in stages" :key="`stage-${index}`" class="mb-2">
					<article class="position-relative border-settings rounded p-2">
						<b-badge variant="danger" style="top: -8px; left: -8px" class="position-absolute">
							{{ item.order }}
						</b-badge>
						<div class="mb-1" style="height: 80px">
							<h4>{{ item.name }}</h4>
							<small>{{ item.description }}</small>
						</div>

						<b-row>
							<b-col cols="12" lg="6" md="6">
								<label>Dias para activarse</label>
								<div>
									<!-- :max="60" -->
									<vue-number-input
										v-model="item.days_accumulated"
										:min="0"
										inline
										controls
										style="max-width: 150px; margin-top: 5px"
										class="text-center"
									/>
								</div>
							</b-col>
							<b-col cols="12" lg="6" md="6" class="d-flex align-items-center justify-content-end">
								<b-button
									type="button"
									variant="primary"
									style="margin-top: 20px"
									@click="onUpdateStage(item, index)"
								>
									Actualizar
								</b-button>
							</b-col>
						</b-row>
					</article>
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>

<script>
import encasteSettingsService from "@/services/encaste-settings.service";
export default {
	name: "MainEncasteSettings",
	async mounted() {
		await this.getStages();
	},
	data() {
		return {
			stages: [],
		};
	},
	methods: {
		async getStages() {
			this.isPreloading();
			const data = await encasteSettingsService.getStagesFromLote();
			this.stages = data.stages.filter((s) => s.order !== 1);
			this.isPreloading(false);
		},
		async onUpdateStage(stage, index) {
			const { id: stage_id, days_accumulated, order } = stage;

			let item = this.stages.find((s, pos) => pos === index - 1);
			if (item && item.days_accumulated > days_accumulated) {
				this.showToast(
					"danger",
					"top-right",
					"Error",
					"AlertCircleIcon",
					`Los dias no pueden ser mayor a la etapa ${order + 1}`
				);
				return;
			}

			const { status, message } = await encasteSettingsService.updateStage({ stage_id, days_accumulated });
			if (status == 200) {
				this.showSuccessToast(message);
				this.getStages();
			} else {
				this.showToast("danger", "top-right", "Error", "AlertCircleIcon", "Error al actualizar la etapa");
			}
		},
	},
};
</script>

<style lang="scss" scoped>
input.number-input__input {
	text-align: center !important;
}
.border-settings{
	border: 1.5px solid #eff3f5 !important;
}
</style>
