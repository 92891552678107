import axios from "@/axios";

const url = "/api/encaste-settings/";

class EncasteSettingsService {
	async getStagesFromLote() {
		try {
			const { data } = await axios.get(`${url}get-stages-from-lote`);
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async updateStage({ stage_id, days_accumulated }) {
		try {
			const { status, data } = await axios.put(`${url}update-stage/${stage_id}`, {
				days_accumulated,
			});	
			return { status, message: data.message };
		} catch (err) {
			const { status, data } = err.response;
			return { status, message: data.message };
		}
	}
}
export default new EncasteSettingsService();
